import React, {useEffect, useState} from 'react';
import './App.css';
import './style.css';
import sendicon from './images/send.svg'
import 'bootstrap/dist/css/bootstrap.min.css';  
import { AudioRecorder } from 'react-audio-voice-recorder';
import axios from "axios";

export interface conversationObject {
  text: string; bot: boolean; user: any, custom_payloads:any, doctorsTimeall:any
}
const App = () =>{
  const [sendMessage, setsendMessage] = useState("");
  const [randam, setrandam] = useState('')
  const [questionNumber, setquestionNumber] = useState(0)
  const [conversation, setConversation] = useState<conversationObject[]>([]);
  const ref = React.useRef<HTMLDivElement>(null);
  const [questions, setquestions] = useState([{question:"", custom_payloads:[], display:false, answer:""}])
  const [doctorsTime, setdoctorsTime] = useState([{doctor_name:"",available_slots:[]}])
  const [selectedDoctorName, setselectedDoctorName] = useState("")
  const [patient_phone, setpatient_phone] = useState("")
  const addAudioElement = async (blob:any) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    var formData = new FormData()
    formData.append("audio_file", blob)
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    try {
      var department = await axios.post("https://speech.vitelglobal.com/api/speech-to-text/", formData,config);
      if(department.data){
        var temps12 = {"text":department.data.response, "bot":false, 'user':true, custom_payloads:[], doctorsTimeall:[]}
        var tempArray22 = [...conversation, temps12]
          setConversation([...tempArray22])
          var req = { 
            "text": department.data.response,
            "session_id": randam.toString()
          }  
          try {
            const response = await axios.post('https://hc2bot.pranathiss.com/api-chat/', req);
            if(response.data){
              var temps33 = {"text":response.data.response, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
              if(response.data.custom_payloads.length !== 0){
                temps33.custom_payloads = response.data.custom_payloads;
              }
              var tempArray44 = [...conversation, temps12, temps33]
              setConversation([...tempArray44])
              
              setTimeout(() => {
                if(response.data.questions.length !== 0){
                  var temps2 = {"text":response.data.response, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
                  response.data.questions.map((question:any,i:number)=>{
                    if(i == questionNumber){
                      temps2 = {"text":question.question, "bot":true, 'user':false, custom_payloads:question.custom_payloads, doctorsTimeall:[]}
                      question.display = true
                    }
                    return question
                  })
                  setquestions(response.data.questions)
                  var tempArray2 = [...conversation,...tempArray22, temps33, temps2]
                  setConversation([...tempArray2]);
                }
              }, 1000);
              setTimeout(()=>{
                if (ref.current?.scrollHeight) {
                  ref.current.scrollTop = ref.current.scrollHeight;
                }
              }, 500)
            }else{
              alert("enter")
            }
          }catch (error) {
            console.log("not found");
          }
        
      }
    } catch(error) {
      console.log(error)
    }
    //document.body.appendChild(audio);
  };
  const submitdatafromChart = async (e:any) => {

    e.preventDefault()
    var temp = {"text":sendMessage, "bot":false, 'user':true, custom_payloads:[], doctorsTimeall:[]}
    var temps3 = {"text":sendMessage, "bot":false, 'user':true, custom_payloads:[], doctorsTimeall:[]}
    var tempArray = [temp]
    setConversation([...conversation, ...tempArray, ])
    if(questions.length !== 0 && questions[0].question !== ""){
      let questionList = questions
      let number = questionNumber+1;
      setquestionNumber(questionNumber+1)
      questionList.map((question,i)=>{
        if(questionNumber == i){
          question.answer = sendMessage
          question.display = true
        }
        if(i==number){ 
          temps3 = {"text":question.question, "bot":true, 'user':false, custom_payloads:question.custom_payloads, doctorsTimeall:[]}
          var tempArray2 = [...conversation,...tempArray, temps3]
          setConversation([...tempArray2])
        }
        return question
      })

      setquestions([...questionList])
      setsendMessage("")
      if(number == questions.length){
        submitdatafromChartAnswer(sendMessage);
      }
      // var temps2 = {"text":sendMessage, "bot":true, 'user':false, custom_payloads:[]}
      // var tempArray2 = [...conversation,...tempArray, temps2]
      //   setConversation([...tempArray2])
      setTimeout(()=>{
        if (ref.current?.scrollHeight) {
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      }, 500)
      return false

    }
    
    if(doctorsTime.length !== 0 && doctorsTime[0].doctor_name !== ""){
      doctorsTime.map((doctors, i)=>{
        if(doctors.doctor_name == sendMessage){
            console.log(doctors.available_slots)
            //setdoctorsTimeall(doctors.available_slots)
            setselectedDoctorName(sendMessage)
        } 
        return doctors
      })

      return false
    }
    var req = { 
      "text": sendMessage ? sendMessage : "Hi",
      "session_id": randam.toString()
    }  
    try {
      const response = await axios.post('https://hc2bot.pranathiss.com/api-chat/', req);
      if(response.data){
        
        var temps = {"text":response.data.response, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}

        if(response.data.custom_payloads.length !== 0){
          temps.custom_payloads = response.data.custom_payloads;
        }
        var tempArray2 = [...conversation,...tempArray, temps]
        setConversation([...tempArray2]);
        setTimeout(() => {
          if(response.data.questions.length !== 0){
            var temps2 = {"text":response.data.response, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
            response.data.questions.map((question:any,i:number)=>{
              if(i == questionNumber){
                temps2 = {"text":question.question, "bot":true, 'user':false, custom_payloads:question.custom_payloads, doctorsTimeall:[]}
                question.display = true
              }
              return question
            })
            setquestions(response.data.questions)
            var tempArray2 = [...conversation,...tempArray, temps, temps2]
            setConversation([...tempArray2]);
          }
        }, 1000);
        
        setsendMessage("")
        setTimeout(()=>{
          if (ref.current?.scrollHeight) {
            ref.current.scrollTop = ref.current.scrollHeight;
          }
        }, 500)
        
      }else{
        alert("enter")
      }
    }catch (error) {
      console.log("not found");
    }
  }

  const submitdatafromChart1 = async (id:any) => {
    // var temp = {"text":"Hi", "bot":false, 'user':true, custom_payloads:[]}
    // var tempArray = [temp]
    // setConversation([...conversation, ...tempArray, ])
    var req = { 
      "text": sendMessage ? sendMessage : "Hi",
      "session_id": id
    }  
    if (ref.current?.scrollHeight) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
    try {
    const response = await axios.post('https://hc2bot.pranathiss.com/api-chat/', req);
    if(response.data){
      setTimeout(() => {
        var temps = {"text":response.data.response, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
        if(response.data.custom_payloads.length !== 0){
          temps.custom_payloads = response.data.custom_payloads;
        }
        var tempArray2 = [...conversation, temps]
        setConversation([...tempArray2])
        
        
      }, 500);

      setTimeout(()=>{
        if (ref.current?.scrollHeight) {
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      }, 500)
      
    }else{
      alert("enter")
    }
    }catch (error) {
      console.log("not found");
    }
  }

  const submitdatafromChart2 = async (id:any, questionNumber:number) => {
    
    var temp = {"text":id, "bot":false, 'user':true, custom_payloads:[], doctorsTimeall:[]}
    var tempArray = [temp]
    setConversation([...conversation, ...tempArray, ])
    

    if(questions.length !== 0 && questions[0].question !== ""){
      var temps3 = {"text":id, "bot":false, 'user':true, custom_payloads:[],doctorsTimeall:[]}
      let questionList = questions
      let number = questionNumber+1;
      setquestionNumber(number)
      questionList.map((question,i)=>{
        if(questionNumber == i){
          question.answer = id
          question.display = true
        }
        if(i==number){ 
          question.answer = id
          temps3 = {"text":question.question, "bot":true, 'user':false, custom_payloads:question.custom_payloads, doctorsTimeall:[]}
          var tempArray2 = [...conversation,...tempArray, temps3]
          setConversation([...tempArray2]);
          question.display = true;
        }
        return question
      })
      setquestions([...questionList])
      if(questionNumber == questions.length){
        submitdatafromChartAnswer(id);
      }
      setsendMessage("")
      // var temps2 = {"text":sendMessage, "bot":true, 'user':false, custom_payloads:[]}
      // var tempArray2 = [...conversation,...tempArray, temps2]
      //   setConversation([...tempArray2])
      setTimeout(()=>{
        if (ref.current?.scrollHeight) {
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      }, 500)
      return false

    }
    if(doctorsTime.length !== 0 && doctorsTime[0].doctor_name !== ""){
      doctorsTime.map((doctors, i)=>{
        if(doctors.doctor_name == id){
            console.log(doctors.available_slots)
            var temps = {"text":id, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall: doctors.available_slots}
            var tempArray2 = [...conversation,...tempArray, temps]
            setConversation([...tempArray2])
            //setdoctorsTimeall(doctors.available_slots)
            setselectedDoctorName(id)
        } 
        return doctors
      })
      setTimeout(()=>{
        if (ref.current?.scrollHeight) {
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      }, 500)
      return false
    }
    var req = { 
      "text": id,
      "session_id": randam.toString()
    }  
    try {
    const response = await axios.post('https://hc2bot.pranathiss.com/api-chat/', req);
    if(response.data){
      setTimeout(() => {
        console.log(conversation)
        var temps = {"text":response.data.response, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
        if(response.data.custom_payloads.length !== 0){
          temps.custom_payloads = response.data.custom_payloads;
        }
        var tempArray2 = [...conversation,...tempArray, temps]
        setConversation([...tempArray2])
        
        setTimeout(() => {
          if(response.data.questions.length !== 0){
            var temps2 = {"text":response.data.response, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
            response.data.questions.map((question:any,i:number)=>{
              if(i == questionNumber){
                temps2 = {"text":question.question, "bot":true, 'user':false, custom_payloads:question.custom_payloads, doctorsTimeall:[]}
                question.display = true
              }
              return question
            })
            setquestions(response.data.questions)
            var tempArray2 = [...conversation,...tempArray, temps, temps2]
            setConversation([...tempArray2]);
          }
        }, 1000);

      }, 2000);
      setTimeout(()=>{
        if (ref.current?.scrollHeight) {
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      }, 500)
    }else{
      alert("enter")
    }
    }catch (error) {
      console.log("not found");
    }
  }

  const submitdatafromChart3 = async (value:any, doctors:any) => {
    var temp = {"text":value.start_time, "bot":false, 'user':true, custom_payloads:[], doctorsTimeall:[]}
    var tempArray = [temp]
    let req = {
      "doctor_name": selectedDoctorName,
      "date": value.date,
      "start_time": value.start_time,
      "patient_name": "Test",
      "patient_phone":patient_phone
  
    }
    try {
      const response = await axios.post('https://hc2bot.pranathiss.com/api-book-appointment/', req);
      if(response.data){
        setTimeout(() => {
          console.log(conversation)
          var temps = {"text":response.data.message, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
          // if(response.data.custom_payloads.length !== 0){
          //   temps.custom_payloads = response.data.custom_payloads;
          // }
          var tempArray2 = [...conversation,...tempArray, temps]
          setConversation([...tempArray2])
          setselectedDoctorName("");
          setdoctorsTime([{doctor_name:"",available_slots:[]}])
          setTimeout(()=>{
            if (ref.current?.scrollHeight) {
              ref.current.scrollTop = ref.current.scrollHeight;
            }
          }, 500)
        }, 1000);
        
      }else{
        alert("enter")
      }
      }catch (error:any) {
        setTimeout(() => {
          console.log(conversation)
          var temps = {"text":error.response.data.message, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
          // if(error.response.data.custom_payloads.length !== 0){
          //   temps.custom_payloads = error.response.data.custom_payloads;
          // }
          var tempArray2 = [...conversation,...tempArray, temps]
          setConversation([...tempArray2])
          setselectedDoctorName("");
          setdoctorsTime([{doctor_name:"",available_slots:[]}])
          setTimeout(()=>{
            if (ref.current?.scrollHeight) {
              ref.current.scrollTop = ref.current.scrollHeight;
            }
          }, 500)
        }, 1000);
        
        console.log("not found");
      }
  }
  
  const submitdatafromChartAnswer = async (sendMessage:any) => {
    var temp = {"text":sendMessage, "bot":false, 'user':true, custom_payloads:[], doctorsTimeall:[]}
    var tempArray = [temp]
    setConversation([...conversation, ...tempArray, ])
    console.log(questions)
    let req:any = []
    questions.map((que, i)=>{
      let obje = {
        question:que.question,
        answer:que.answer
      }
      req.push(obje)
      return que
    })
    console.log(req)
    // var req = { 
    //   "text": id,
    //   "session_id": randam.toString()
    // }  
    const response = await axios.post('https://hc2bot.pranathiss.com/api-appointment-answers/', req);
    if(response.data){
      console.log(response.data)
      var temps = {"text":response.data.response, "bot":true, 'user':false, custom_payloads:[], doctorsTimeall:[]}
        if(response.data.doctors.length !== 0){
          let doctor:any = [];
          response.data.doctors.map((doc:any, i:any)=>{
            doctor.push(doc.doctor_name)
            return doc
          })
          temps.custom_payloads = doctor;
          setquestions([{question:"", custom_payloads:[], display:false, answer:""}])
          setpatient_phone(response.data.patient_phone)
          setdoctorsTime(response.data.doctors)
          console.log(response.data.doctors)
          
        }
        var tempArray2 = [...conversation,  ...tempArray, temps,]
        setConversation([...tempArray2])
        setTimeout(()=>{
          if (ref.current?.scrollHeight) {
            ref.current.scrollTop = ref.current.scrollHeight;
          }
        }, 500)
    }
  }
// id of the chat container ---------- ^^^

  useEffect(()=>{
    var tempvsdf = Math.floor(Math.random()*100000+1)
    setrandam(tempvsdf.toString())
    submitdatafromChart1(tempvsdf.toString());
   
  }, [])
  return (
    <>
      <div className="sidebar-active position-relative">
        <div className="chitchat-container sidebar-toggle">
          <div className="chitchat-main small-sidebar" id="content">
            <div className="chat-content tabto active" >
              <div className='chatBoatHeader'>C3Bot</div>
              <div className="messages custom-scroll active" id="chating" ref={ref} >
                <div className="contact-chat"  >
                  <ul className="chatappend">
                    {conversation && conversation.map((itme, i)=>{
                      return (
                        itme.bot ? <li className="sent">
                        <div className="media">
                          {/* <div className="profile mr-4"><img className="bg-img" src={userImage} alt="Avatar"/></div> */}
                          <div className="media-body">
                          <div className="contact-name" >
                          
                            <ul className="msg-box">
                              <li className="msg-setting-main">
                                <h5>{itme.text}</h5>
                              </li>
                              

                              {itme.custom_payloads.map((items:any, i:any)=>{
                                  return (
                                    questions.length !== 0 && questions[0].question !== "" ? 
                                    <div className='suggestions' onClick={()=>submitdatafromChart2(items, questionNumber)}>{items}</div> :

                                    <div className='suggestions' onClick={()=>submitdatafromChart2(items, 0)}>{items}</div>
                                  )
                                })}
                                {itme.doctorsTimeall.map((items:any, i:any)=>{
                                  return (

                                    <div className='suggestions' onClick={()=>submitdatafromChart3(items, doctorsTime)}>{items.start_time + "-" + items.end_time} </div>

                                   
                                  )
                                })}
                                {/* <li className="msg-setting-main">
                                {questions.map((ques, i)=>{
                                  return <div className={ques.display ? "" :'d-none'}>
                                    <h5>{ques.question}</h5><br/>
                                    {ques.custom_payloads.map((items:any, i:any)=>{
                                    return (
                                      <div className='suggestions' onClick={()=>submitdatafromChart2(items)}>{items}</div>
                                    )
                                  })}<br/><hr/> </div>
                                 
                                })}
                              </li> */}
                            </ul>
                            
                          </div>
                            
                          </div>
                        </div>
                      </li> :  itme.user ?  <li className="replies">
                      <div className="media" >
                        {/* <div className="profile mr-4"><img className="bg-img" src={userImage} alt="Avatar"/></div> */}
                        <div className="media-body">
                        <div className="contact-name">
                          <ul className="msg-box">
                              <li className="msg-setting-main">
                                <h5> {itme.text}</h5>
                              </li>
                            
                            </ul>
                          </div>
                       
                        </div>
                      </div>
                    </li> : ""
                      )
                    })}
                    
                  </ul>

                  {/* <ul className="msg-box">
                    <li className="msg-setting-main">
                      {doctorsTimeall.map((ques, i)=>{
                        return <div className="">
                          <h5>{JSON.stringify(ques)}</h5><br/>
                          <br/><hr/> </div>
                        
                      })}
                    </li>
                  </ul> */}
                </div>
              </div>
              
              <form className="message-input" action='#' onSubmit={submitdatafromChart}>
                <div className="wrap emojis-main"  >
                  <input className="setemoj" id="setemoj" type="text" value={sendMessage} onChange={(e)=>setsendMessage(e.target.value)} placeholder="Write your message..." />
                  <button  type='submit' className="icon-btn btn-outline-primary button-effect mr-3 ml-3"><img src={sendicon} alt='send'/></button>
                <AudioRecorder onRecordingComplete={addAudioElement} />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='BotText' id='BotText'>
          <h2 className='text-muted'>A <span className='text-success fs-3'>Modern Approach</span><br /> to <span className='text-danger fs-3'>Guided Self-Booking</span><br /> with <span className='text-warning fs-3'>Newage Symptom Analyzer</span></h2>
          <div className='text-right text-secondary PocSize'> <small>"POC"</small></div>
        </div>

        {/* <div className='BotBottomText' id='BotBottomText'>
          <p className='text-secondary'></p>
        </div> */}
      </div>


    {/* <div className='container'>
      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-8 mt-4'>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Chart Bot</h5>
            <div className='chartHistory'>
              <ul className='list-style '>
                <li className='client'>How are you doing?</li>
                <li  className='me'>Doing Good</li>
                <li className='client'>Where are you from?</li>
                <li  className='me'>I am from hyderabad</li>
                <li className='client'>Which language are you speek</li>
                <li  className='me'>I will speek multiple languages</li>
                <li className='client'></li>
                <li  className='me'>Welcome To chart</li>
                <li className='client'>Welcome To chart</li>
                <li  className='me'>Welcome To chart</li>

              </ul>
            </div>
            <div className="form-group inputHolderForbot">
              <input type="text" className='inputTextField' aria-describedby="emailHelp" placeholder="Please ask a question" />
              <a className='micIconPosition'>
             
              </a>
            </div>
          </div>

        </div>

        </div>
      </div>
      
    </div> */}
    </>
  );
}

export default App;
